/* Create a CSS layout for a main element and a footer element. The footer element should be sticky at the bottom. */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  margin-bottom: 7rem; /* adjust this value to match the height of your footer */
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 4fr;
}

footer {
  color: #fff;
  padding-bottom: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6rem; /* adjust this value to match the height of your footer */
  z-index: 0;
  margin-left: 20%;
  /* display: grid; */
  /* grid-template-columns: 1fr 4fr; */
}

aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
}

.history {
  margin: 10px;
}

.new-message {
  position: relative;
  width: 60%;
  /* add a light grey border */
  border: 1px solid #ccc;
  /* a subtle drop shadow */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  padding: 5px;
  border-radius: .75rem;
  margin-left: 10%;
  box-sizing: border-box;
}

textarea {
  margin: auto;
  /* don0t allow resize */
  resize: none;
  /* full width */
  width: 100%;
  /* no border */
  border: none;
  box-sizing: border-box;
  /* center the element */
  display: block;
}

textarea:focus {
  outline: none;
}

.message {
  /* set height according to content */
  height: auto;
  padding: 1rem 1rem 1rem 3rem;
  /* bottom border dark grey */
  border-bottom: 1px solid #ccc;
  /* center the text in the div vertically */
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1)
}

.message.bot {
  /* background color of light blue */
  background-color: rgba(247, 247, 248, 1);
}

/* Button used to open the contact form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  border-radius: .375rem;
  width: 100%;
  margin-bottom: 10px;
}

input, button, select {
  border-radius: .375rem;
}

/* The popup form - hidden by default */
.form-popup {
  display: none;
  max-width: 300px;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  /* padding: 10px; */
}

/* Full-width input fields */
.form-container input[type="text"],
.form-container input[type="password"],
.form-container select,
.form-container input[type="number"] {
  width: 92%;
  padding: 4%;
  margin: 1% 0 1% 0;
  border: none;
  background: #f1f1f1;
}

.form-container select {
  width: 100%;
}

/* When the inputs get focus, do something */
.form-container input[type="text"]:focus,
.form-container input[type="password"]:focus,
.form-container select:focus,
.form-container input[type="number"]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.btn {
  background-color: #04aa6d;
  color: white;
  padding: 4%;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.red-text {
  color: red;
}

form {
  /* center this element */
  margin: 0 auto;
}

ul {
  padding: 0;
  margin: 0;
  height: 500px;
  overflow: auto;
}

.new-chat-ul {
  height: 50px;
}

li {
  list-style: none;
  padding: 12px;
}

li.active {
  background-color: rgba(52, 53, 65, 1);
  border-radius: 0.375rem;
}

p.profile {
  padding: 12px;
  background-color: rgba(52, 53, 65, 1);
  border-radius: 0.375rem;
}

li a {
  text-decoration: none;
  color: #fff;
  /* make cursor point */
  cursor: pointer;
}

aside {
  background-color: rgba(32, 33, 35, 1);
  color: #fff;
}

.new-chat{
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  border-radius: .375rem;
}

.chat-history li {
  display: flex;
  justify-content: space-between;
}

.icons svg {
  cursor: pointer;
}

.icons svg[data-icon="ban"],
.icons svg[data-icon="trash"] {
  margin-left: 10px;
}

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  outline: 0;
  background: #4CAF50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
  background: #43A047;
}
.form .account-message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .account-message a {
  color: #4CAF50;
  text-decoration: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}
.user-form-container {
  background: #000000; /* fallback for old browsers */
  margin: auto;
  height: 100%;
}

.login-with {
  margin-top: 8px;
}

.newMessage {
  padding: 16px 16px 16px 16px;
  height: 48px;
}

.click-div {
  cursor: pointer;
}

.scrollView {
  margin-bottom: 10rem;
}
